import * as React from "react";

function ChevronRight(props) {
  const { stroke, ...restProps } = props
  return (
    <svg width={11} height={17} stroke={stroke} fill="none" strokeWidth={4} className="chevron-right-svg" {...restProps}>
      <path
        d="M3.418 13.505l4.415-5.323-4.415-4.99"
        strokeLinecap="square"
      />
    </svg>
  );
}

// Set default props
ChevronRight.defaultProps = {
  stroke: "#339285",
};

export default ChevronRight;