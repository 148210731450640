import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import "./gallery.sass";

const Gallery = ({ props }) => (
  <section className="container">
    <div className="masonry pb-6">
      {props
        ? props.map((item) => (
            <div key={item.imageAlt} className="masonry-item">
              {item.image ? (
                <div>
                  {item.link ? (
                    <Link to={item.link}>
                      {item.imageAlt ? (
                        <PreviewCompatibleImage
                          imageInfo={{
                            image: item.image,
                            alt: item.imageAlt,
                          }}
                        />
                      ) : (
                        <PreviewCompatibleImage
                          imageInfo={{
                            image: item.image,
                          }}
                        />
                      )}
                    </Link>
                  ) : (
                    <div>
                      {item.imageAlt ? (
                        <PreviewCompatibleImage
                          imageInfo={{
                            image: item.image,
                            alt: item.imageAlt,
                          }}
                        />
                      ) : (
                        <PreviewCompatibleImage
                          imageInfo={{
                            image: item.image,
                          }}
                        />
                      )}
                    </div>
                  )}
                </div>
              ) : null}
            </div>
          ))
        : null}
    </div>
  </section>
);

Gallery.propTypes = {
  heading: PropTypes.string,
  subheading: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  content: PropTypes.string,
  imageAlt: PropTypes.string,
  embed: PropTypes.string,
  link1: PropTypes.object,
  link2: PropTypes.object,
};

export default Gallery;
