import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import ChevronRight from "../components/icons/ChevronRight";
import remark from "remark";
import recommended from "remark-preset-lint-recommended";
import remarkHtml from "remark-html";
import { kebabCase } from "lodash";

const ContentBlock = ({ props }) => (
  <section className="has-background-black content-block" key={props.heading}>
    <div className="columns is-gapless is-multiline">
      {props.image ? (
        <div className="column is-6 py-0 h-100-children">
          {props.imageAlt ? (
            <PreviewCompatibleImage
              imageInfo={{
                image: props.image,
                alt: props.imageAlt,
              }}
            />
          ) : (
            <PreviewCompatibleImage
              imageInfo={{
                image: props.image,
              }}
            />
          )}
        </div>
      ) : null}
      {props.embed ? (
        <div className="column is-6 py-0">
          <iframe
            width="100%"
            height="100%"
            loading="lazy"
            style={{ minHeight: "368px" }}
            src={props.embed.replace("youtu.be/", "youtube.com/embed/")}
            frameBorder="0"
            title={props.heading ? props.heading : null}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      ) : null}
      {props.heading || props.content || props.link1 || props.link2 ? (
        <div className="column is-6 py-0 align-self-center">
          <div className="container p-10p has-text-white py-20p-tablet pt-0">
            <div className="mb-5">
              <h2
              className="has-text-white mb-2 pt-10p pt-20p-tablet"
              data-sal="slide-left"
              data-sal-easing="ease-out-back"
              data-sal-delay="200"
              data-sal-duration="500"
              id={kebabCase(props.heading)}
            >
              {props.heading}
            </h2>
            {props.subheading ? (
              <h3
                className="has-text-white mt-0"
                data-sal="slide-left"
                data-sal-easing="ease-out-back"
                data-sal-delay="400"
                data-sal-duration="500"
              >
                {props.subheading}
              </h3>
            ) : null}
            </div>
            <div
              data-sal-delay="600"
              data-sal="fade"
              className="mb-5"
              dangerouslySetInnerHTML={{
                __html: remark()
                  .use(recommended)
                  .use(remarkHtml)
                  .processSync(props.content)
                  .toString(),
              }}
            />
            {props.link1 && props.link1.text && props.link1.url && (
              <p data-sal-delay="800" data-sal="fade">
                <Link
                  className="featured-link has-text-primary"
                  to={props.link1.url}
                >
                  {props.link1.text}
                  <ChevronRight />
                </Link>
              </p>
            )}
            {props.link2 && props.link2.text && props.link2.url && (
              <p data-sal-delay="1000" data-sal="fade">
                <Link
                  className="featured-link has-text-primary"
                  to={props.link2.url}
                >
                  {props.link2.text}
                  <ChevronRight />
                </Link>
              </p>
            )}
          </div>
        </div>
      ) : null}
    </div>
  </section>
);

ContentBlock.propTypes = {
  heading: PropTypes.string,
  subheading: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  content: PropTypes.string,
  imageAlt: PropTypes.string,
  embed: PropTypes.string,
  link1: PropTypes.object,
  link2: PropTypes.object,
};

export default ContentBlock;
