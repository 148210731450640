import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import ContentBlock from "../components/ContentBlock";
import Banner from "../components/Banner";
import MainPitch from "../components/MainPitch";
import Features from "../components/Features";
import CTA from "../components/CTA";
import LoginComponent from "../components/LoginComponent";
import Gallery from "../components/Gallery";

export const PageTemplate = ({
  helmet,
  banner,
  mainpitch,
  contentSections,
  content,
  contentComponent,
  gallery,
  features,
  cta,
}) => {
  const PageContent = contentComponent || Content;
  return (
    <div>
      {helmet || ""}
      <Banner props={banner} />
      {mainpitch && <MainPitch props={mainpitch} />}
      <div className="content position-relative has-background-white">
        {content && typeof content !== "undefined" ? (
          <section className="container page-content">
            {mainpitch ? (
              <PageContent className="py-10p pt-0" content={content} />
            ) : (
              <PageContent className="py-10p" content={content} />
            )}
          </section>
        ) : null}
        {gallery ? <Gallery props={gallery} /> : null}
        {contentSections?.length ? (
          contentSections.map((contentSection) => (
            <div
              className="content-block-container"
              key={contentSection.heading}
            >
              <div>
                {features &&
                features.order &&
                contentSections[features.order - 1] === contentSection ? (
                  <Features features={features} />
                ) : null}
                <ContentBlock props={contentSection} />
              </div>
            </div>
          ))
        ) : (
          <Features features={features} />
        )}
      </div>
      <CTA props={cta} />
    </div>
  );
};

PageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  banner: PropTypes.object,
  mainpitch: PropTypes.object,
  features: PropTypes.shape({
    blurbs: PropTypes.array,
    heading: PropTypes.string,
    link1: PropTypes.object,
    link2: PropTypes.object,
  }),
  cta: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    button: PropTypes.shape({
      text: PropTypes.string,
      url: PropTypes.string,
    }),
  }),
  gallery: PropTypes.array,
  contentSections: PropTypes.array,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const Page = ({ data }) => {
  const { markdownRemark: post } = data;
  return (
    <Layout>
      <PageTemplate
        contentComponent={HTMLContent}
        helmet={
          <Helmet>
            <title>{`${post.frontmatter.title} | EVOLVE Strong Fitness`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        banner={post.frontmatter.banner}
        mainpitch={post.frontmatter.mainpitch}
        contentSections={post.frontmatter.contentSections}
        content={post.html}
        gallery={post.frontmatter.gallery}
        features={post.frontmatter.features}
        cta={post.frontmatter.cta}
      />
    </Layout>
  );
};

Page.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Page;

export const pageQuery = graphql`
  query Page($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
        banner {
          image {
            childImageSharp {
              fluid(
                maxWidth: 2048
                quality: 64
                srcSetBreakpoints: [960, 1440]
              ) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          imageAlt
          video {
            publicURL
          }
          bannerType
          heading
        }
        mainpitch {
          title
          description
        }
        gallery {
          image {
            childImageSharp {
              fluid(maxWidth: 512, quality: 64) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          imageAlt
          link
        }
        contentSections {
          heading
          subheading
          content
          image {
            childImageSharp {
              fluid(maxWidth: 1024, quality: 64) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          imageAlt
          embed
          link1 {
            text
            url
          }
          link2 {
            text
            url
          }
        }
        features {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 512, maxHeight: 512, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            imageAlt
            heading
            link
          }
          heading
          order
          link1 {
            text
            url
          }
          link2 {
            text
            url
          }
        }
        cta {
          heading
          description
          button {
            text
            url
          }
        }
      }
    }
  }
`;
