import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const CTA = ({ props }) => (
  <div>
    {props && (props.heading || props.description || props.button) ? (
      <section className="has-background-primary position-relative">
        <div className="container content">
          <div className="columns is-centered is-gapless">
            <div className="column is-10-tablet is-8-widescreen py-0 align-self-center">
              <div className="p-10p has-text-white has-text-centered">
                {props.heading ? (
                  <h2
                    data-sal="slide-left"
                    data-sal-easing="ease-out-back"
                    data-sal-duration="500"
                    data-sal-delay="200"
                    className="has-text-white mb-6"
                  >
                    {" "}
                    {props.heading}{" "}
                  </h2>
                ) : null}

                {props.description ? (
                  <p
                    data-sal="fade"
                    data-sal-delay="400"
                    className="mb-6 is-size-5"
                  >
                    {props.description}
                  </p>
                ) : null}

                {props.button && props.button.text && props.button.url && (
                  <p data-sal="fade" data-sal-delay="600">
                    <Link
                      className="featured-link button is-white has-text-primary"
                      to={props.button.url}
                    >
                      {props.button.text}
                    </Link>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    ) : null}
  </div>
);

CTA.propTypes = {
  heading: PropTypes.string,
  description: PropTypes.string,
  button: PropTypes.shape({
    text: PropTypes.string,
    url: PropTypes.string,
  }),
};

export default CTA;
