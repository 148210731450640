import React from "react";
import PropTypes from "prop-types";
import PreviewCompatibleImage from "./PreviewCompatibleImage";
import ChevronRight from "./icons/ChevronRight";
import { Link } from "gatsby";
import "./features.css";

const FeatureGrid = ({ features }) => (
  <div>
    {features && (
      <section className="p-10p has-background-light features">
        {features.heading && (
          <h2
            className="mb-5"
            data-sal="flip-up"
            data-sal-easing="ease-out-back"
            data-sal-duration="500"
            data-sal-delay="200"
          >
            {features.heading}
          </h2>
        )}
        {features.blurbs && (
          <div className="columns is-multiline is-mobile mnx-5p features-columns is-centered">
            {features.blurbs.map((item, index) => (
              <div
                key={item.heading}
                className="column is-6-mobile is-3-tablet px-5p py-6"
              >
                <div
                  className="position-relative"
                  data-sal="zoom-out"
                  data-sal-duration="500"
                  data-sal-delay={index * 200}
                >
                  {item.link ? (
                    <Link to={item.link} className="features-blurb-link">
                      <PreviewCompatibleImage
                        imageInfo={{ image: item.image, alt: item.imageAlt }}
                        className={`grow`}
                      />
                      <h3 className="features-blurb-heading">{item.heading}</h3>
                    </Link>
                  ) : (
                    <div>
                      <PreviewCompatibleImage
                        imageInfo={{ image: item.image, alt: item.imageAlt }}
                      />
                      <h3 className="features-blurb-heading">{item.heading}</h3>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}

        {features.link1 || features.link2 ? (
          <div className="mt-5 has-text-centered">
            {features.link1 && features.link1.text && features.link1.url && (
              <p data-sal="flip-up" data-sal-delay="200">
                <Link
                  className="featured-link has-text-primary"
                  to={features.link1.url}
                >
                  {features.link1.text}
                  <ChevronRight />
                </Link>
              </p>
            )}
            {features.link2 && features.link2.text && features.link2.url && (
              <p data-sal="flip-up" data-sal-delay="400">
                <Link
                  className="featured-link has-text-primary"
                  to={features.link2.url}
                >
                  {features.link2.text}
                  <ChevronRight />
                </Link>
              </p>
            )}
          </div>
        ) : null}
      </section>
    )}
  </div>
);

FeatureGrid.propTypes = {
  features: PropTypes.shape({
    blurbs: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        heading: PropTypes.string,
        imageAlt: PropTypes.string,
        link: PropTypes.string,
      })
    ),
    heading: PropTypes.string,
    link1: PropTypes.object,
    link2: PropTypes.object,
  }),
};

export default FeatureGrid;
