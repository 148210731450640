import React from "react";
import PropTypes from "prop-types";

const MainPitch = ({ props }) => (
  <div>
    {props.title || props.description ? (
      <div className="content has-background-white position-relative">
        <div className="container">
          <div className="page-content has-text-centered p-10p">
            {props.title ? (
              <h1
                data-sal="flip-up"
                data-sal-easing="ease-out-back"
                data-sal-duration="500"
                data-sal-delay="200"
                className="is-size-3a mb-5 has-text-weight-normal has-letter-spacing-normal is-transform-unset"
              >
                {props.title}
              </h1>
            ) : null}
            {props.description ? (
              <h2
                data-sal="fade"
                data-sal-delay="400"
                className="is-size-3a is-transform-unset has-text-weight-light has-letter-spacing-normal"
              >
                {props.description}
              </h2>
            ) : null}
          </div>
        </div>
      </div>
    ) : null}
  </div>
);

MainPitch.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default MainPitch;
